import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/600.css"
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/400.css";
import street from "../images/street.webp"
import stars from "../images/stars.webp"
import Scroll from '../components/scroll';
import { Helmet } from "react-helmet";
import i18next from "i18next";

const Story = () => {

    const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);
    return (
        <main>
            <Scroll showBelow={250} />
            <Helmet htmlAttributes={{ lang: language }}>
                <meta charSet="utf-8" />
                <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
                <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
                <meta name="author" content="Rejoice" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{i18next.t("story.header.title")}</title>
            </Helmet>
            <Header />
            <div className="md:relative py-16 bg-white">

                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="md:relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg
                            className="absolute top-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg
                            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg
                            className="absolute bottom-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="md:relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
                            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {i18next.t('story.title')}
                            </span>
                        </h1>
                        
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p1')}
                        </p>
                        <figure>
                            <img
                                className="w-full rounded-lg mt-6"
                                src={street}
                                alt="people walking around the street"
                                width={1200}
                                height={801}
                            />
                        </figure>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p2')}
                        </p>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p3')}                        </p>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p4')}                        </p>
                        <figure>
                            <img
                                className="w-full rounded-lg mt-6"
                                src={stars}
                                alt="a person looking to a high-colored moon"
                                width={1310}
                                height={873}
                            />
                        </figure>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p5')}                        </p>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p6')}                        </p>
                        <p className="mt-8 text-xl text-justify text-gray-500 leading-8">
                        {i18next.t('story.text.p7')}                        </p>
                    </div>
                    
                </div>

            </div>
            <Footer />
        </main>
    )
}
export default Story;